<template>
    <div class="wrapper -proposito padding-menu">
        <div class="breadcrumb">
            <div class="container row-end">
                <router-link to="/">Grupo IAN</router-link>
                <router-link to="/" style="pointer-events: none;">{{ $t('navbar_menu.group_ian.title') }}</router-link>
                <router-link :to="$route.path">{{ $t('purpose_vision_values.title') }}</router-link>
            </div>
        </div>

        <section class="intro-page">
            <div class="container">
                <h1>{{ $t('purpose_vision_values.title') }}</h1>
                <img :src="$t('purpose_vision_values.main_banner.img')" alt="img main banner">
                <div class="green-box">
                    <h2>{{ $t('purpose_vision_values.main_banner.title') }}</h2>
                    <div v-html="$t('purpose_vision_values.main_banner.text')"></div>
                </div>
            </div>
        </section>

        <section class="banner-proposito">
            <div class="container row-start">
                <div class="texto">
                    <h2>{{ $t('purpose_vision_values.purpose_banner.title') }}</h2>
                    <div v-html="$t('purpose_vision_values.purpose_banner.text')"></div>
                </div>
                <img :src="$t('purpose_vision_values.purpose_banner.img1')" alt="Quiénes somos" class="proposito-1 qsomos">
                <img :src="$t('purpose_vision_values.purpose_banner.img2')" alt="Quiénes somos" class="proposito-2">
                <img :src="$t('purpose_vision_values.purpose_banner.img3')" alt="Quiénes somos" class="proposito-3">
            </div>
        </section>

        <section class="imagen-texto">
            <div class="container row-start">
                <img :src="$t('purpose_vision_values.vision_banner.img')" alt="">
                <div class="texto">
                    <h2 class="main-title">{{ $t('purpose_vision_values.vision_banner.title') }}</h2>
                    <div v-html="$t('purpose_vision_values.vision_banner.text')"></div>
                </div>
            </div>
        </section>

        <banner-valores :view="'purpose_vision_values'"></banner-valores>
    </div>
</template>

<script>
import bannerValores from '@/components/banner-valores'
export default {
    name: 'quienes-somos',
    components: {
        'banner-valores': bannerValores
    },
}
</script>

<style lang="scss" scoped>
@media screen and (max-width:1040px) {
    .intro-page img {
        width: 100%;
    }
}
</style>